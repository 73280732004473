<template>
    <div class="recommended-content-card">
        <a v-if="img != ''" :href="url" :class="`card-image ${imgProportions}`">
            <img :src="img">
        </a>
        <a v-else :href="url" :class="`card-image landscape`">
            <img
                src="@assets/img/memo-placeholder.jpg"
                loading="lazy"
                :alt="title"
            >
        </a>
        <div class="card-container">
            <a class="card-title" :href="url">{{ title }}</a>
            <a :href="`/${creatorDisplayname}/memos`" class="author text-truncate">
                <div class="user-icon">
                    <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.80005 10.1191C2.80005 9.07568 3.64233 8.2334 4.68576 8.2334H8.87624C9.91967 8.2334 10.762 9.07568 10.762 10.1191V11.5858C10.762 12.1641 10.2926 12.6334 9.71433 12.6334H3.84767C3.26938 12.6334 2.80005 12.1641 2.80005 11.5858V10.1191Z"
                            stroke="#EBEBF5"
                            stroke-opacity="0.6"
                            stroke-width="0.7"
                            stroke-miterlimit="10"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.78138 2.36621C5.39433 2.36621 4.26709 3.49345 4.26709 4.8805C4.26709 6.26754 5.39433 7.39478 6.78138 7.39478C8.16842 7.39478 9.29566 6.26754 9.29566 4.8805C9.29566 3.49345 8.16842 2.36621 6.78138 2.36621Z"
                            stroke="#EBEBF5"
                            stroke-opacity="0.6"
                            stroke-width="0.7"
                            stroke-miterlimit="10"
                        />
                    </svg>
                </div>
                <span>
                    {{ creatorDisplayname }}
                </span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditorLinkCard",
    props: {
        img: {
            type: String,
            default: ""
        },
        imgProportions: {
            type: String,
            default: "landscape"
        },
        url: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        creatorDisplayname: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.recommended-content-card {
    text-decoration: none;
    display: flex;
    border-radius: 10px;
    height: 130px;
    overflow: hidden;
    background-color: #2C2C2E !important;
    border: 1px solid rgba(84, 84, 88, 0.65);

    .card-image {
        background-color: #242425;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 130px;

        img {
            height: 130px;
        }

        img {
            @media(max-width: 510px) {
                width: initial;
                height: 100%;
            }
        }
    }

    .card-container {
        color: white;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;

        .card-title {
            color: white;
            font-size: 18px !important;
            line-height: 18px !important;
            margin-bottom: 5px;
            display: block;
            overflow: hidden;
            margin-bottom: auto;
            text-decoration: none;
        }

        .author {
            font-size: 14px !important;
            color: rgba(235, 235, 245, 0.6) !important;
            display: flex;
            align-items: center;

            .user-icon {
                margin-right: 5px;
            }
        }
    }
}
</style>
